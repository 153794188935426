import React from "react";
import { Link, graphql } from "gatsby";

export default ({ node }) => (
    <article className="py-8 border-b border-grey border-dashed">
        <Link to={`/blog/${node.frontmatter.path}`} className="font-serif no-underline hover:underline text-4xl mb-8">{node.frontmatter.title}</Link>
        <p className="mt-4 text-lg text-grey-dark uppercase">{node.frontmatter.date}</p>
        <p className="text-lg leading-normal tracking-normal mt-8 mb-8">{node.excerpt}</p>
        <div className="mt-8 flex justify-between">
            <div className="hidden md:flex flex-row">
                {node.frontmatter.tags.map((tag, i, arr) => (
                    <>
                        <Link key={`tag_${tag}`} className="text-primary" to={`/blog/tag/${tag}`}>{tag}</Link>
                        {i !== arr.length-1 ? (<span>,&nbsp;</span>) : ""} 
                    </>
                ))}
            </div>
            <Link className="text-primary" to={`/blog/${node.frontmatter.path}`}>Read more</Link>
        </div>
    </article>
);

export const query = graphql`
    fragment BlogPost on MarkdownRemark {
        frontmatter {
          title
          date(formatString: "MMM DD, YYYY")
          path
          tags
        }
        excerpt
    }
`