import React from "react";
import { Link } from "gatsby";

export default ({ current, total, prevLink, nextLink }) => (
    <div className="flex flex-row justify-between py-8 text-lg">
        {current !== 1 ? (
            <Link className="text-tertiary" to={prevLink || `/blog/posts/${current-1}`}>Previous</Link>
        ) : (<span></span>)}
        {current < total && (
            <Link className="text-tertiary" to={nextLink || `/blog/posts/${current+1}`}>Next</Link>
        )}
    </div>
)