import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Post from "../components/blog/post-summary";
import Pagination from "../components/blog/pagination";

const BlogIndexPage = ({ data, pageContext }) => {
    return (
      <Layout>
        <div className="max-w-3xl px-4 py-8 bg-cover bg-center mx-auto">
            <h1 className="text-tertiary text-5xl">blog</h1>
            <div className="flex flex-row">
              <div className="mx-auto flex flex-col md:w-2/3 p-4">
              {data.posts.edges.map(({ node }, i) => (<Post key={`post_${i}`} node={node} />))}
              <Pagination current={pageContext.currentPage} total={pageContext.numPages} />
              </div>
            </div>
        </div>
      </Layout>
    );
};

export default BlogIndexPage;
export const query = graphql`
query ($skip: Int!, $limit: Int!){
   posts: allMarkdownRemark(
     filter: {frontmatter: {draft: {ne: true}}}, 
     sort: {fields: frontmatter___date, order: DESC}, 
     limit: $limit,
     skip: $skip
    ) {
    edges {
      node {
        ...BlogPost
      }
    }
  }
}
`